<script>
import smoothscroll from 'smoothscroll-polyfill';

export default {
  name: 'scrollTo',

  beforeMount() {
    smoothscroll.polyfill();
  },

  methods: {
    scrollToTop() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      });
    },

    scrollToPosition(position) {
      this.$nextTick(() => {
        window.scrollTo({ top: position, behavior: 'smooth' });
      });
    },
  },

};
</script>
