<template>
<div>
  <darkBackground />
  <downloadPicker />
  <div v-if="selectedLernpfad" class="w-full h-full md:p-5 font-visby text-new-black">
    <div class="relative bg-cover bg-no-repeat bg-center h-96 rounded-xl md:shadow-new overflow-hidden" :style="{ backgroundImage: 'url(' + selectedLernpfad?.titelbild?.data?.attributes?.url + ')' }">
      <transition @enter="fadeIn" @leave="fadeOut" :css="false">
      <div v-if="fetching" class="absolute inset-0 w-full h-full flex flex-row justify-center items-center">
        <div class="absolute inset-0 z-50 w-full h-full flex flex-row justify-center items-center">
          <spinSVG :light="true" class="w-7 h-7" />
        </div>
        <div class="w-full h-full bg-black opacity-10"></div>
      </div>
      </transition>
      <iframe v-if="selectedLernpfad.videolink" class="w-full h-full" :src=selectedLernpfad.videolink title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
    <div class="flex flex-col md:flex-row md:justify-between items-start md:items-center mt-5 md:my-6 px-5 md:p-0" :class="{'animate-pulse' : fetching}">
      <div class="font-visby font-bold text-3xl mt-3 md:mt-0">{{selectedLernpfad?.Bezeichnung}}</div>
      <div v-if="selectedLernpfad?.download.length >= 1" class="flex bg-white flex-row justify-center items-center p-2 px-4 my-3 rounded-xl shadow-new cursor-pointer" @click="openDownloadPicker">
        <downloadSVG />
        <div class="ml-3 mr-1">Downloads</div>
      </div>
    </div>
    <div class="px-5 md:p-0 mt-1 md:mt-0" :class="{'animate-pulse' : fetching}">{{selectedLernpfad?.Kurzbeschreibung}}</div>
    <div class="mt-6 flex flex-col justify-between md:justify-start items-start md:items-start" :class="{'animate-pulse' : fetching}">
      <div class="font-visby font-bold text-lg px-5 md:p-0">In diesem Lernpfad</div>

      <!-- Filter-->
      <div class="flex-shrink-0 mt-0.5 flex flex-row text-new-midGrey overflow-y-scroll whitespace-nowrap scrollbar max-w-full px-5 md:p-0">
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Alle'}" @click="currentFilter = 'Alle'">Alle</div>
        <div v-for="filter in filter" :key=filter :class="{'cursor-pointer mr-3 select-none' : filter.length > 0}" @click="currentFilter = filter">
          <div :class="{'text-new-black' : filter === currentFilter}">{{filter}}</div>
        </div>
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Offen'}" @click="currentFilter = 'Offen'">Offen</div>
        <div class="cursor-pointer mr-3 select-none" :class="{'text-new-black' : currentFilter === 'Abgeschlossen'}" @click="currentFilter = 'Abgeschlossen'">Abgeschlossen</div>
      </div>
      <!-- Filter-->

      <div class="flex flex-row text-new-midGrey text-sm px-5 md:p-0">
        <div>{{ filteredLernspots.length }} {{ filteredLernspots.length === 1 ? 'Lernspot' : 'Lernspots' }}</div>
        <div v-if="selectedLernpfad?.Zeitaufwand && currentFilter === 'Alle'" class="ml-1.5">· {{selectedLernpfad.Zeitaufwand}} Stunden</div>
      </div>

    </div>
    <div class="flex flex-row flex-wrap px-2.5 md:px-0 w-full mb-20 md:mb-0" :class="{'animate-pulse' : fetching}">
      <div :id="lernspot.id" v-for="lernspot in filteredLernspots" :key=lernspot class="pl-2.5 pr-2.5 w-1/2 md:max-w-md my-2.5" @click="openLernspot(lernspot.id)">
      <lernpfadPreview
        :previewImage=lernspot?.Titelbild?.data?.attributes?.url
        :title=lernspot?.Bezeichnung
        :description=lernspot?.Kurzbeschreibung
        :lernspotsDone=getFinishedSlides(lernspot.id)
        :lernspotsTotal=lernspot?.slides.length
        :reduced="true"
        :bgColor="lernspot?.bgColor"
        itemName="Slide" />
      </div>
    </div>
  </div>
  <div v-else-if="fetching" class="w-full h-screen flex flex-row justify-center items-center">
    <spinSVG class="w-7 h-7" />
  </div>
</div>
</template>

<script>
// Mixins
import iosVibrations from '../../../mixins/iosVibrations.vue';
import detectNotch from '../../../mixins/detectNotch.vue';
import fadeInOut from '../../../mixins/animations/fadeInOut.vue';
import scrollTo from '../../../mixins/scrollTo.vue';

// SVG
import downloadSVG from '../../../assets/svg/download.vue';
import spinSVG from '../../../assets/svg/spin.vue';

// Components
import darkBackground from '../../../symbols/darkBackground.vue';
import lernpfadPreview from './lernpfadPreview.vue';
import downloadPicker from './downloadPicker.vue';

export default {
  name: 'selectedLernpfadPreview',
  components: {
    downloadSVG, lernpfadPreview, downloadPicker, darkBackground, spinSVG,
  },
  mixins: [iosVibrations, detectNotch, fadeInOut, scrollTo],

  data() {
    return {
      currentFilter: 'Alle',
    };
  },

  computed: {
    selectedLernpfad() {
      return this.$store.state.learning.selectedLernpfad?.lernpfade?.data?.attributes;
    },
    fetching() {
      return this.$store.state.learning.isFetchingSelectedLernpfad;
    },
    selectedLernpfadId() {
      return this.$store.state.learning.selectedLernpfadId;
    },
    selectedLernspotId() {
      return this.$store.state.learning.selectedLernspotId;
    },
    lernpfadProgress() {
      return this.$store.state.profile.me.userData.data.attributes.lernpfadProgress;
    },
    filter() {
      const filter = [];
      this.selectedLernpfad.lernspots.forEach((lernspot) => {
        if (!filter.includes(lernspot.Filter) && lernspot.Filter !== null) {
          filter.push(lernspot.Filter);
        }
      });
      return filter;
    },
    filteredLernspots() {
      const filteredLernpfade = [];
      if (this.currentFilter !== 'Alle' && this.currentFilter !== 'Offen' && this.currentFilter !== 'Abgeschlossen') {
        this.selectedLernpfad.lernspots.forEach((lernspot) => {
          if (lernspot.Filter === this.currentFilter) {
            filteredLernpfade.push(lernspot);
          }
        });
        return filteredLernpfade;
      }
      if (this.currentFilter !== 'Alle') {
        this.selectedLernpfad.lernspots.forEach((lernspot) => {
          const lernspotsDone = this.getFinishedSlides(lernspot.id);
          const lernspotsTotal = lernspot?.slides.length;
          if (this.currentFilter === 'Abgeschlossen' && lernspotsDone === lernspotsTotal) {
            filteredLernpfade.push(lernspot);
          }
          if (this.currentFilter === 'Offen' && lernspotsDone !== lernspotsTotal) {
            filteredLernpfade.push(lernspot);
          }
        });
        return filteredLernpfade;
      }
      return this.selectedLernpfad?.lernspots;
    },
  },

  watch: {
    fetching() {
      console.log(`FETCHING -> ${this.fetching}`);
    },
  },

  mounted() {
    if (this.selectedLernspotId !== null) {
      const element = document.getElementById(this.selectedLernspotId);
      if (element) element.scrollIntoView();
    } else {
      this.scrollToTop();
    }
  },


  methods: {
    openSkript(url) {
      window.open(url, '_blank').focus();
    },

    openDownloadPicker() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', 'downloadPicker');
    },

    openLernspot(id) {
      this.iosVibrate(0);
      this.$store.commit('setSelectedLernspotId', id);
      this.$router.push({ name: 'lernspot' });
    },

    getFinishedSlides(lernspotId) {
      const lernpfadIndex = this.lernpfadProgress?.lernpfade.findIndex((lernpfad) => lernpfad.id === this.selectedLernpfadId);
      if (lernpfadIndex === -1) {
        return 0;
      }
      const lernspotIndex = this.lernpfadProgress?.lernpfade[lernpfadIndex]?.lernspots.findIndex((spot) => spot.id === lernspotId);
      if (lernspotIndex === -1) {
        return 0;
      }
      return this.lernpfadProgress?.lernpfade[lernpfadIndex]?.lernspots[lernspotIndex].slides.length;
    },
  },
};
</script>
