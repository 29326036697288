<script>
export default {
  name: 'progressBarWidth',
  methods: {
    calculateWidth(max, current) {
      if (max === current) {
        return 'w-full';
      }
      const donePercent = (100 / max) * current;
      const fractal = Math.round((donePercent * 1.2) / 10);
      if (fractal === 12) {
        return 'w-full';
      }
      if (fractal === 0 && current > 0) {
        return 'w-1/12';
      }
      return `w-${fractal}/12`;
    },
  },
};
</script>
