<script>
export default {
  name: 'mobile',
  data() {
    return {
      mobile: true,
    };
  },
  mounted() {
    if (document.documentElement.clientWidth >= 786) {
      this.mobile = false;
    }
  },
};
</script>
