<template>
  <div class="w-full h-full bg-white flex flex-col rounded-xl p-4 md:p-4 font-visby text-new-black shadow-new cursor-pointer" :class="{'md:flex-row' : horizontal, 'p-2.5' : reduced}">
    <div class="w-full md:w-40 h-40 md:h-40 rounded-xl bg-no-repeat bg-center flex-shrink-0 shadow-new"
    :class="{'md:w-full' : !horizontal, 'h-64' : !reduced, 'bg-cover' : cover, 'bg-contain' : !cover}"
    :style="{ backgroundImage: 'url(' + previewImage + ')' , 'background-color': bgColor}"></div>
    <div class="flex flex-col justify-between flex-grow overflow-hidden" :class="{'md:ml-5 ' : horizontal}">
      <div class="mt-3" :class="{'md:mt-0' : horizontal}">
        <div lang="de" class="font-visby font-bold text-sm hyphen" :class="{'text-xl' : !reduced}" :ref="setRef">{{ title }}</div>
        <div class="md:block mt-1" :class="{'hidden' : reduced}">{{description}}</div>
      </div>
      <div class="mt-3" :class="{'md:mt-0' : horizontal}">
        <div class="relative w-full h-1.5 bg-new-lightGrey2 rounded-full">
          <div class="absolute h-full bg-new-orange rounded-full" :style="{'background-color': currentCourseColor2}" :class="[calculateWidth(lernspotsTotal, lernspotsDone)]"></div>
        </div>
        <div class="text-new-midGrey mt-1 text-sm text-right italic">{{lernspotsRemaining}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// Mixins
import colors from '../../../mixins/colors.vue';
import progressBarWidth from '../../../mixins/progressBarWidth.vue';

export default {
  props: ['previewImage', 'title', 'description', 'lernspotsDone', 'lernspotsTotal', 'itemName', 'horizontal', 'reduced', 'bgColor', 'cover'],
  name: 'lernpfadPreview',
  mixins: [progressBarWidth, colors],

  computed: {
    lernspotsRemaining() {
      const remaining = this.lernspotsTotal - this.lernspotsDone;
      if (remaining === 0) {
        return 'abgeschlossen';
      }
      if (remaining === 1) {
        return `noch ${remaining} ${this.itemName}`;
      }
      return `noch ${remaining} ${this.itemName}s`;
    },
  },

  methods: {
    setRef(ref) {
      if (ref) {
        const currentTitle = ref.innerHTML;
        const newTitle = currentTitle.replace('. ', '.&nbsp;');
        // eslint-disable-next-line no-param-reassign
        ref.innerHTML = newTitle;
      }
    },
  },
};
</script>

<style>
.hyphen {
    -webkit-hyphens: auto; /* for Safari */
    hyphens: auto;
  }
</style>
